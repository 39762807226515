/* You can add global styles to this file, and also import other style files */

@use "@angular/material" as mat;
@include mat.core();
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Roboto:wght@300;400&display=swap");

$defaultBackground: #fafafa;

$woolworths-ocean: (
  50: #e6f4fd,
  100: #c0e0fb,
  200: #98ccfa,
  300: #70b8f8,
  400: #4ba6f7,
  500: #1971ed,
  600: #1665ca,
  700: #1359a7,
  800: #104d84,
  900: #0d4161,
  A100: #b5d5ff,
  A200: #82c1ff,
  A700: #499dff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
  ),
);

$woolworths-midnight: (
  50: #e6e6eb,
  100: #c0c0c9,
  200: #9898a7,
  300: #707085,
  400: #4b4b63,
  500: #0e0d26,
  600: #0c0b23,
  700: #0a0920,
  800: #08071d,
  900: #06051a,
  A100: #6e6e7d,
  A200: #4d4d5c,
  A700: #2d2d3b,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
  ),
);

h1 {
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 38px;
  line-height: 42px;
  font-weight: 500;
}

h2 {
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 28px;
  line-height: 32px;
  font-weight: 500;
}

h4 {
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
}

$davinci-team-salary-frontend-primary: mat.define-palette($woolworths-ocean);
$davinci-team-salary-frontend-accent: mat.define-palette($woolworths-midnight);
$davinci-team-salary-frontend-warn: mat.define-palette(mat.$red-palette);
$davinci-team-salary-frontend-theme: mat.define-light-theme(
  (
    color: (
      primary: $davinci-team-salary-frontend-primary,
      accent: $davinci-team-salary-frontend-accent,
      warn: $davinci-team-salary-frontend-warn,
    ),
  )
);

@include mat.all-component-themes($davinci-team-salary-frontend-theme);

$primaryColor: mat.get-color-from-palette(
  $davinci-team-salary-frontend-primary
);
$accentColor: mat.get-color-from-palette($davinci-team-salary-frontend-accent);
$warnColor: mat.get-color-from-palette($davinci-team-salary-frontend-warn);

.primaryColor {
  color: $primaryColor;
}

.accentColor {
  color: $accentColor;
}

.warnColor {
  color: $warnColor;
}

:root {
  --white: #fff;
  --light-grey: #d5d5d5;
  --primary: #1b79ff;
  --secondary: #66c5ff;
  --dark: #0e0d26;
  --dark_90: rgba(30, 26, 52, 0.9);
  --complimentary-one: #f3ea5d;
  --complimentary-two: #ff6720;
  --complimentary-three: #dfd1a7;
  --complimentary-four: #00b74f;
  --complimentary-five: #93328e;
  --light: #f8f9fa;
  --max-content-width: 1200px;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--light);
  font-family: Montserrat-Regular, Arial, Helvetica, sans-serif;
}

.btn {
  background-color: var(--dark);
  border: 0;
  border-radius: 20px;
  color: var(--white);
  margin: 20px 5px;
  text-decoration: none;
  padding: 0.6rem 1rem;
  font-weight: bold;
  font-size: 0.9rem;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* gap between cards */
  padding: 20px;
  max-width: 1200px; /* optional max width for better layout on very large screens */
  margin: 0 auto; /* center the container */
}

.flex-card {
  flex: 1 1 calc(33.33% - 40px); /* width of cards minus twice the gap to account for left and right spacing */
  background-color: var(--white);
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex; /* This and the following properties ensure that cards in the same row have equal heights */
  flex-direction: column;
  justify-content: space-between;
}

.flex-column {
  flex: 1 1 calc(33.33% - 40px); /* width of cards minus twice the gap to account for left and right spacing */
  padding: 20px;
  box-sizing: border-box;
  display: flex; /* This and the following properties ensure that cards in the same row have equal heights */
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .card {
    flex: 1 1 100%; /* cards take the full width on mobile */
  }
  .flex-column {
    flex: auto;
    padding: 0;
  }
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* Default styles for mobile */
.card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Styles for desktop view (e.g., screens wider than 768px) */
@media (min-width: 768px) {
  .card-wrapper {
    flex-direction: row;
    justify-content: space-around;
  }
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.bold {
  font-weight: bold;
}

/* Contentful Styles */
app-section-mapper .contentful-content {
  h2 {
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-size: 28px;
    line-height: 32px;
    font-weight: 500;
  }

  a {
    color: mat.get-color-from-palette($woolworths-ocean, 300);
    &:hover {
      color: black;
    }
  }

  table {
    width: 100%;
    border-radius: 25px;
    border-collapse: collapse;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-size: 0.9em;
    font-family: sans-serif;
    overflow: hidden;
  }

  th,
  td {
    padding: 12px 15px;
  }

  tbody tr {
    border-bottom: 1px solid #dddddd;
  }
}

.richTextImage,
.richTextVideo {
  width: 100%;
  display: block;
}
.richTextVideo {
  max-height: 50vh;
}

.error-message {
  color: #f44336;
}

.terms-container {
  .contentful-content {
    width: 85%;
  }
}
